import React from 'react';

function ExpeditionsInNepal() {
  return (
    <div>
      <h1>Expeditions in Nepal</h1>
      <p>Content for Expeditions in Nepal...</p>
    </div>
  );
}
export default ExpeditionsInNepal;
